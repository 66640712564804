import { Box } from '@chakra-ui/react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import React from 'react'
export const FooterContactNotes: React.FC<any> = () => {
    return (
        <Box pt={'20px'}>
            <Text
                fontSize={'16px'}
                color={'var(--Monochrome-800, #615A80)'}
                fontWeight={'normal'}
            >
                For help getting a quote please call:{' '}
                <a
                    href={'tel:+353(0)1 704 1104'}
                    style={{
                        color: '#3681D9',
                    }}
                >
                    {' '}
                    +353(0)1 704 1104
                </a>
            </Text>
        </Box>
    )
}

export const FooterCommonNotes: React.FC<any> = () => {
    return (
        <>
            <li>
                <Text
                    fontSize={'16px'}
                    color={'var(--Monochrome-800, #615A80)'}
                    fontWeight={'normal'}
                >
                    Your personal details will only be used to deal with your
                    request. See the{' '}
                    <a
                        href='/privacy-notices'
                        style={{
                            color: '#3681D9',
                        }}
                    >
                        ILFS privacy notice
                    </a>{' '}
                    for your rights and how your information is used. See also
                    the Irish Life Financial Service Terms of Business{' '}
                    <a
                        href='/sites/retail/files/Terms of Business ILFS.pdf'
                        style={{
                            color: '#3681D9',
                        }}
                    >
                        here
                    </a>
                    .
                </Text>
            </li>
            <li>
                <Text
                    fontSize={'16px'}
                    color={'var(--Monochrome-800, #615A80)'}
                    fontWeight={'normal'}
                >
                    Before you complete your quote, please note that we may
                    contact you to discuss your quote and answer any questions
                    you may have. If you prefer not to be contacted please email{' '}
                    <a
                        style={{
                            color: '#3681D9',
                        }}
                        href='mailto: customersuccessteam@irishlife.ie'
                    >
                        customersuccessteam@irishlife.ie
                    </a>{' '}
                    to remove your email address from our contact list. Please
                    note that it may take up to 24 hours to process your
                    request.
                </Text>
            </li>
        </>
    )
}
