import { assign, createMachine, actions } from 'xstate'
import {
    PensionFormType,
    InitialQuestionType,
    InvestmentFormType,
    IncomeProtectionFormType,
    TermLifeFormType,
} from './interfaces'
const { choose } = actions

export const CalculatorMachine =
    /** @xstate-layout N4IgpgJg5mDOIC5QGECGAbAxgV3agLgPYBOAsqpgBYCWAdmAHQCixxJAxGlrgWAAQBBKKjoBtAAwBdRKAAOhWNXzVCtGSAAeiAIwBmAGz6GATlPGATAHYAHAFZtty-usAaEAE9EtgCy2G+3Vtda2DrS0DjfQBfKLcuHDwiMgoaegZ4nnwwdgB5WgAxEgBbAFVZCF4JaSQQeUVlVXUtBG9rcX9I6299c28bc3EnN08EIO0GXWNbENt9Yz054xi4jASCEnIqOkYMxOyASVos4gosiCr1OqUVNRrm23NzCd1LX29W3oHbYcRvQIZ3lZxNpDNZHgFdMsQLt1sktmkYVl2ABxMD4PgARWwhCyFxqVwat1AzRsugY4l02gGVIGlN0PwQhiMwSCs2senE4misWhq0yGxS23SfL27AAcmBIHwABJgdCyPFyBTXRp3LziJ7iKbmYzWNraYy+cwM3STAHWSLmRxzP5hKGIgXwnYi3jisAadEAZSyCqkl2VhKaXgc-n0tlsFhB1kMswZvTJQX0fwM5gNk0hPIdcNSjCxOP4nswxEltBRaMEEAAVthYGdMdjcX78QGbkGEOZdO1xN09ZTgRrZvSPDpXgCxuI9Qa+t3uStuIlHTmGHmsnxC8WwKWAEIUADWitqLdVxMQHa7PZC2n7VoCDOm7UsVp781MlMs9pdSU2S5XBaLJc4T9+CEERaAPAlWzVdtO3JC8+yvG8hxGAJxkeHxHwtcM2m8D951hb8hQABWIaha22dhDmuDBSMgcCjyJTRTxgmdeyvBDBwZeZrAYbQbH0LkuMfHDM0-RchVRfBlFoKB63zPgABE0REdBYHYCBVEYOgADdCF3RgYHwX9FPwZTYDo+pIJPBBSXJSlqUeCk9AZSwnAYAZ3P44xLCvWxcLWL9BTSCSpJk38FKU6gVPYMBWBIBhZESAAzYoGAMoyIpU8yVQY5o9AMckHAjA0pm7cNnJCAqDSCYxdGpXi-P5bMhQAJTARK0XhUKG34YzTLUjSGG03T9LRdKTMisymyVCzj0Y6Dz2cS9rw44cEHZBhbE5Tkgm23sMznfyxLSVr2vwTrZNXXqJui2LiHipKUrS7qrsyqbDxmnLEBsxz7NpJzVpBJ5E0mLbe28DUYh5WhCAgOB1CzAj6H9D62wAWn0Bl0fJLacdx1MGoXJq0hYNhiGR7K2wcP4GAtcRZgw-RtG0O9uh4wJautTsKXfES8ICp1hT5sBycDKDWQYfobG7LpOT+Bkw24toBkZ2ZDECbQCfwwLc26td-03EXLLm7xjVWmw-AsaMHm7cwk0MTX+aXYjSKk4XmxRqC7H8WZUyw8NvG0C1OIDgE6aZ4JA6Z8MHaOxhgroLq5Je+B3YpqDTCeXpTGcAdTBCZzpg2pnDQNbRum8LyY6JxgTo61JE8ujKU+mtOrL+Yw3LDAIgksH3A4xgHglsgIXNMMvvKWSGgA */
    createMachine(
        {
            context: {
                initialQuestions: {} as InitialQuestionType[],
                formValues: {} as
                    | PensionFormType
                    | InvestmentFormType
                    | IncomeProtectionFormType
                    | TermLifeFormType,
                isRestarted: false,
                screenNo: 0,
                calc_type: '',
                interacted: false,
                actions: {
                    onSubmit: async (args: any) => {},
                    analyticsService: {
                        onInteracted: (
                            name: string,
                            event?: string,
                            isInteracted?: string
                        ) => {},
                        onCompleted: (name: string, event: string) => {},
                        onButtonClicked: (
                            name: string,
                            step: number,
                            event: string
                        ) => {},
                        handleOtherAnalytics: (
                            analyticsName: string,
                            formValues: any,
                            screenNo: number
                        ) => {},
                    },
                    createCrmLead: async (args: any, calc_type: string) => {},
                },
                quoteData: {} as any,
            },
            schema: {},
            id: 'CalculatorMachine',
            initial: 'Pristine',
            states: {
                Error: {
                    on: {
                        'Calculate Again': {
                            target: 'Calculate',
                            actions: ['restartCalculatorOnError'],
                        },
                    },
                },
                Calculate: {
                    on: {
                        OnFormUpdate: {
                            target: 'Calculate',
                            internal: true,
                            actions: ['updateFormValues'],
                        },
                        Interacted: {
                            target: 'Calculate',
                            internal: true,
                            actions: ['onInteracted'],
                        },

                        'Get Quote': {
                            target: 'Getting Quote Details',
                            actions: ['updateFormValues'],
                        },
                        'Need Help': {
                            target: 'Calculate',
                            internal: true,
                            actions: ['onNeedHelpClicked'],
                        },

                        'Next Step': {
                            target: 'Calculate',
                            internal: true,
                            actions: ['updateCounter'],
                        },
                    },
                },

                'Quote Screen': {
                    on: {
                        'Get Adjusted Quote': {
                            target: 'Refetching Quote Details',
                            actions: ['updateFormValues'],
                        },
                        Back: 'Calculate',
                        'Calculate Again': {
                            target: 'Calculate',
                            actions: ['restartCalculator'],
                        },
                        'ERROR OCCURED': {
                            target: 'Error',
                        },
                    },
                },

                Pristine: {
                    on: {
                        Initialise: {
                            target: 'Calculate',
                            actions: ['setInitialQuestions'],
                        },
                    },
                },

                'Getting Quote Details': {
                    invoke: {
                        src: 'getQuoteDetails',
                        id: 'getQuoteDetails',
                        onDone: {
                            target: 'Quote Screen',
                            actions: ['updateQuoteData'],
                        },
                        onError: {
                            target: 'Error',
                        },
                    },
                    entry: choose([
                        {
                            cond: (context: any) => !context.isRestarted,
                            actions: ['createCRMLead'],
                        },
                        {
                            actions: () => {}, // Do nothing if isRestarted is true
                        },
                    ]),
                },

                'Refetching Quote Details': {
                    invoke: {
                        src: 'getQuoteDetails',
                        id: 'getQuoteDetails',
                        onDone: {
                            target: 'Quote Screen',
                            actions: ['updateQuoteData'],
                        },
                        onError: {
                            target: 'Error',
                        },
                    },
                },
            },
        },
        {
            actions: {
                restartCalculatorOnError: assign((context, event: any) => {
                    return {
                        screenNo: 0,
                    }
                }),

                onNeedHelpClicked: assign((context, event: any) => {
                    context.actions?.analyticsService?.onButtonClicked?.(
                        event?.payload?.name,
                        context.screenNo,
                        event?.payload?.event
                    )
                    return {}
                }),
                onInteracted: assign((context, event: any) => {
                    if (!context.interacted) {
                        context.actions?.analyticsService?.onInteracted?.(
                            event?.payload?.name,
                            event?.payload?.event,
                            event?.payload?.isInteracted
                        )
                        return {
                            interacted: true,
                        }
                    }
                    return {}
                }),
                updateQuoteData: assign({
                    quoteData: (context, event: any) => {
                        return event?.data
                    },
                }),
                setInitialQuestions: assign({
                    initialQuestions: (context, event: any) => {
                        return event?.payload?.props.steps
                    },
                    calc_type: (context, event: any) => {
                        return event?.payload?.props?.calc_type
                    },
                    actions: (context, event: any) => {
                        return event?.payload?.actions
                    },
                }),
                updateFormValues: assign({
                    formValues: (context, event: any) => {
                        return event?.payload?.user
                    },
                }),
                restartCalculator: assign((context, event: any) => {
                    return {
                        screenNo: 0,
                        isRestarted: true,
                    }
                }),
                updateCounter: assign((context, event: any) => {
                    context.actions?.analyticsService?.onButtonClicked?.(
                        event?.payload?.name,
                        context.screenNo,
                        event?.payload?.event
                    )
                    context.actions?.analyticsService?.handleOtherAnalytics?.(
                        event?.payload?.name,
                        event?.payload?.user,
                        context.screenNo
                    )
                    return { screenNo: context.screenNo + 1 }
                }),
                createCRMLead: (context, event: any) => {
                    context.actions?.createCrmLead(
                        event?.payload?.user,
                        event?.payload?.calc_type
                    )
                    context.actions?.analyticsService?.onCompleted?.(
                        event?.payload?.name,
                        event?.payload?.event
                    )
                },
            },
            services: {
                getQuoteDetails: async (context, event: any) => {
                    return await context.actions.onSubmit(event?.payload?.user)
                },
            },
        }
    )
