import { Box } from '@chakra-ui/react'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { PhoneIcon } from '@irishlife/ilgroupdesignsystem.icons'
import React from 'react'

export interface GetStartedProps {}

export const GetStarted: React.FC<GetStartedProps> = () => {
    return (
        <Box pt={'12px'} mb={'16px'}>
            <Text variant={'title-xs'}>Get started</Text>
            <Box mt={'16px'}>
                <Text mb={'16px'} variant={'body-md'}>
                    Our expert financial advisors are on hand to help you get
                    started.
                </Text>
                <a href='tel:+353(01)7041979'>
                    <Button
                        leftIcon={
                            <PhoneIcon fontSize={'28px'} color={'#FFFFF'} />
                        }
                        variant='primary'
                        size='md'
                    >
                        Call us (01) 704 1979
                    </Button>
                </a>

                <Text mt={'24px'} variant={'body-md'}>
                    This indicative quote from Irish Life Financial Services is
                    for a Term Life Insurance policy from Irish Life Assurance
                    plc and is subject to further assessment of health status.
                    This assessment may result in an increase in the cost of
                    cover. Irish Life Assurance plc, trading as Irish Life, is
                    regulated by the Central Bank of Ireland.
                </Text>
            </Box>
        </Box>
    )
}
