import { pensionsTool } from './pensions'
import { investmentTool } from './investment'
import { incomeProtectionTool } from './incomeProtection'
import { termLifeTool } from './termLife'
export const config: { [key: string]: any } = {
    Pensions: pensionsTool,
    Investment: investmentTool,
    IncomeProtection: incomeProtectionTool,
    TermLife: termLifeTool,
}
