import { Box } from '@chakra-ui/react'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import React from 'react'
import numeral from 'numeral'

export interface QuoteBreakdownProps {
    quoteData: any
}

export const QuoteBreakdown: React.FC<QuoteBreakdownProps> = ({
    quoteData,
}) => {
    const costOfQuote =
        Number(quoteData?.prem) -
        Number(quoteData?.fee) -
        Number(quoteData?.levy)
    return (
        <Box pt={'12px'} mb={'16px'}>
            <Text variant={'title-xs'}>Your quote breakdown</Text>
            <Box mt={'16px'}>
                <Text variant={'label-md'}>Cost of life cover:</Text>
                <Text variant={'label-md'} color={'var(--Black, #0C1E68)'}>
                    {`€${numeral(costOfQuote).format('0.00')}`}
                </Text>
            </Box>
            <Box mt={'16px'}>
                <Text variant={'label-md'}>Irish Life Policy Fee:</Text>
                <Text variant={'label-md'} color={'var(--Black, #0C1E68)'}>
                    {`€${quoteData?.fee?.toLocaleString()}`}
                </Text>
            </Box>
            <Box mt={'16px'}>
                <Text variant={'label-md'}>1% Government Levy:</Text>
                <Text variant={'label-md'} color={'var(--Black, #0C1E68)'}>
                    {`€${quoteData?.levy?.toLocaleString()}`}
                </Text>
            </Box>
        </Box>
    )
}
