import { Box } from '@chakra-ui/react'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import React from 'react'

export interface ValidityCardProps {}

export const ValidityCard: React.FC<ValidityCardProps> = () => {
    return (
        <Box pt={'12px'} mb={'16px'}>
            <Card px={'36px'}>
                <Text variant={'title-xs'}>Validity</Text>
                <Box mt={'16px'}>
                    <Text variant={'body-md'}>
                        This quote is valid either for 7 days or to your next
                        birthday, whichever is sooner. If you would like to
                        discuss options for Joint Life Cover or Dual Life Cover,
                        you can book a free consultation with a financial
                        advisor.
                    </Text>
                </Box>
            </Card>
        </Box>
    )
}
