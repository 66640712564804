import React from 'react'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import { Flex, Divider } from '@chakra-ui/react'
import { Loading } from 'common/components/organisms/Calculator/components/quoteComponents/Loading'
import { useCalculatorMachine } from 'common/components/organisms/Calculator/utils/context'
import { QuoteBreakdown } from './components/QuoteBreakdown'
import { GetStarted } from './components/GetStarted'
import { AdjustmentForm } from './components/AdjustmentForm'

export interface TermLifeCalculatorOutputProps {}

export const TermLifeCalculatorOutput: React.FC<TermLifeCalculatorOutputProps> =
    () => {
        const { state } = useCalculatorMachine()
        const isRefetching = state.matches('Refetching Quote Details')
        const quoteData = state.context.quoteData.quoteData
        return (
            <>
                <Card>
                    <Text variant={'title-sm'} mb={'12px'}>
                        {' '}
                        Your quote{' '}
                    </Text>
                    {isRefetching ? (
                        <Loading value={'premium'} />
                    ) : (
                        <>
                            <Flex mb={'28px'}>
                                <Text
                                    variant={'title-xl'}
                                    color={'var(--Brand-800, #1D2F7C)'}
                                >
                                    {`€ ${quoteData?.prem?.toLocaleString()}`}{' '}
                                </Text>
                                <Text
                                    pt={'14px'}
                                    pl={'6px'}
                                    variant={'body-md'}
                                >
                                    /per month
                                </Text>
                            </Flex>
                            <AdjustmentForm />
                            <Divider
                                orientation='horizontal'
                                borderColor='#C4C2D0'
                                borderWidth='1px'
                            />
                            <QuoteBreakdown quoteData={quoteData} />
                            <Divider
                                orientation='horizontal'
                                borderColor='#C4C2D0'
                                borderWidth='1px'
                            />
                            <GetStarted />
                        </>
                    )}
                </Card>
            </>
        )
    }
